<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <data-table
      :data-source="sortedAnimaux"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <router-link
          :to="{name: 'add_animal'}"
          class="btn btn-outline-primary btn-icon"
          v-if="is_assistant_veto || is_labo_user || is_technicien_imagerie || is_veterinaire || is_assistant_veto_major || is_super_admin"
        >
          <i class="icofont icofont-plus" />
        </router-link>
      </template>
    </data-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import DataTable from '../../../components/dataTable/local.vue'
import {fullnameComponentSort, libelleComponentSort} from '../../../components/dataTable/functions'
import navbar from '../../../components/navbar.vue'
import { COMPONENT_TYPE, DATE_TYPE, TEXT_TYPE } from '../../../components/dataTable/dataType'
const Espece = () => import('../../../components/identification/animal/espece.vue')
const Race = () => import('../../../components/identification/animal/race.vue')
const Robe = () => import('../../../components/identification/animal/robe.vue')
const Proprio = () => import('../../../components/identification/animal/proprio.vue')
const Action = () => import('../../../components/identification/animal/action.vue')
const AnimalPhoto = () => import('../../../components/identification/animal/photo.vue')

export default {
    components: { navbar,  DataTable },
    data(){
        return {
          navbarItems: [{
                libelle: 'Identification'
              },
              {
                libelle: 'Animaux'
              }],
              pageIcon: 'la-dog',
              pageTitle: 'Animaux',
              pageDescription: 'Liste des animaux'
        }
    },
    methods: {
      

    },
    computed: {
        ...mapGetters({
            animaux: 'identification/animaux',
            especes: 'identification/especes',
            robes: 'identification/robes',
            races: 'identification/races',
            typeRobes: 'identification/typeRobes',
            aliments: 'identification/aliments',
            proprios: 'auth/proprietaires',
            is_veterinaire: 'auth/is_veterinaire',
            is_super_admin: 'auth/is_super_admin',
            is_assistant_veto_major: 'auth/is_assistant_veto_major',
            is_assistant_veto: 'auth/is_assistant_veterinaire',
            is_labo_user: 'auth/is_labo_user',
            is_technicien_imagerie: 'auth/is_technicien_imagerie'
            
        }),
        sortedAnimaux(){
            return [...this.animaux].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
        },
        headers(){
            return [
                {label: 'UID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                {label: 'Photo', name: 'photo', type: COMPONENT_TYPE, component: AnimalPhoto},
                {label: 'Code ISO', name: 'codeIso', type: TEXT_TYPE, sortable: true},
                {label: 'Nom', name: 'name', type: TEXT_TYPE, sortable: true},
                {label: 'Espece', name: 'espece', type: COMPONENT_TYPE, component: Espece, sortable: true, function : (order, source)  => libelleComponentSort(order, source, this.especes, 'espece')},
                {label: 'Race', name: 'race', type: COMPONENT_TYPE, component: Race, sortable: true, function: (order, source) => libelleComponentSort(order, source, this.races, 'race')},
                {label: 'Robe', name: 'robe', type: COMPONENT_TYPE, component: Robe, sortable: true, function: (order, source) => libelleComponentSort(order, source, this.robes, 'robe')},
                {label: 'Proprietaire', name: 'proprio', type: COMPONENT_TYPE, component: Proprio, sortable: true, function: (order, source) => fullnameComponentSort(order, source,this.proprios, 'proprio')},
                {label: 'Date Naissance', name: 'dateNaissance', type: DATE_TYPE, sortable: true},
                {label: 'Crée le', name: 'createdAt', type: DATE_TYPE, sortable: true},
                {label: 'Actions', name:'action', type: COMPONENT_TYPE, component: Action}
            ]
        }
    }
}
</script>