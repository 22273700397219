<template>
  <div class="page-header card">
    <div class="row align-items-end">
      <div class="col-lg-6 col-md-7">
        <div class="page-header-title">
          <a
            href="javascript:void(0);"
            class="sidebarCollapse"
            data-placement="bottom"
          >
            <i :class="iconFont" />
          </a>
            
          <div class="d-inline">
            <h4>{{ navbartitle }}</h4>
            <span>{{ navbardescription }}</span>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-5">
        <div class="page-header-breadcrumb">
          <ul class="breadcrumb-title">
            <li class="breadcrumb-item">
              <router-link :to="{name: 'home'}">
                <i
                  class="las la-home"
                  style="font-size: 18px;"
                />
              </router-link>
            </li>
            <li
              v-for="(item, index) in items"
              :key="index"
              class="breadcrumb-item"
            >
              <router-link
                v-if="linked(item)"
                :to="route(item)"
              >
                {{ item.libelle }}
              </router-link>
              <span v-else>{{ item.libelle }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
  export default {
    props: {
      navbartitle: { type: String },
      navbardescription: { type: String },
      fonticon: { type: String },
      items: { type: Array, required: true }
    },
    data () {
      return {
        iconFont: null
  
      }
    },
    mounted () {
      this.iconFont = 'las ' + this.fonticon
    },
    methods: {
      linked (item) {
        return item.route !== undefined
      },
      route (item) {
        if (item.params !== undefined) return { name: item.route, params: { ...item.params } }
        return { name: item.route }
      }
    }
  
  }
  </script>
  <style lang="scss" scoped>
.page-header{
    padding: 25px 20px;
    border: solid 1px #1C6100;
    border-radius: 10px;
    margin-top: 40px;
    margin-bottom: 50px !important;
    box-shadow: 1px 5px #1C6100;
    .page-header-title{
        display: flex;
        flex-wrap: wrap;
        i{
            color: #BB1B1B;
            margin-right: 20px;
            font-size: 50px;
        }
        .d-inline{
            h4{
                font-size: 20px;
                color: #BB1B1B;
                font-weight: bold;
            }
            span{
                font-size: 14px;
                color: #1C6100
            }
        }
    }
    .page-header-breadcrumb{
        text-align: right;
        .breadcrumb-title{
            display: inline-flex !important;
            list-style-type: none;
            .breadcrumb-item{
                font-size: 16px;
                color: #1C6100;
                a:hover{
                    text-decoration: none;
                }
            }
        }

    
    }
}
  </style>
  